import { Flex } from '@chakra-ui/react';
import Link from '../Link';
import { Link as LinkProps } from '../../types';

const FormLegalLinks = ({ legal }: { legal: LinkProps[] }) => (
  <Flex pt="4" columnGap="4">
    {legal.map((l: LinkProps) => (
      <Link key={l.url} href={l.url} target={l.target}>
        {l.title}
      </Link>
    ))}
  </Flex>
);

export default FormLegalLinks;
